// Globle Imports
@import "../globle.scss";

.layout-container {
  display: grid;
  grid-template-columns: 11rem 1fr;
  grid-template-rows: 4rem 1fr;
  grid-template-areas: "Navbar Navbar" "Sidebar Content";
  transition: 0.5s;
}

@media screen and (max-width: 768px) {
  .layout-container {
    display: grid;
    grid-template-columns: 3.6rem 1fr;
    grid-template-rows: 4rem 1fr;
    grid-template-areas: "Navbar Navbar" "Sidebar Content";
  }
}

@media screen and (max-width: 586px) {
  .layout-container {
    display: grid;
    grid-template-columns: 11rem 1fr;
    grid-template-rows: 3rem 1fr;
    grid-template-areas: "Navbar Navbar" "Content Content";
  }
}
