.form_input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input_space {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.input_label {
  font-size: max(0.9722222222vw, 12px);
  line-height: 1.2;
  font-weight: 700;
  color: rgb(36 32 33);
  display: flex;
  gap: 0.25rem;
}
.label_text {
  font-size: max(0.9722222222vw, 12px);
  line-height: 1.2;
  font-weight: 700;
  color: rgb(36 32 33);
}
.important_sign {
  color: rgba(253 46 25/1);
}
.input_field {
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  color: rgb(36 32 33);
  resize: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  font-size: max(0.9722222222vw, 12px);
  line-height: 1.6;
  font-weight: 400;
}

.form_headline {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
.form_headline_ts {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--font_black);
}
.form_subheadline_ts {
  font-size: 1rem;
  font-weight: 400;
  color: var(--font_grey);
}

.form_submit_button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 0.4rem;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.6rem 1.25rem;
  background-color: var(--btn_primary) !important;
  border: 2px solid var(--btn_primary) !important;
  color: #fff !important;
}

.mi_login_form {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}

.forgot_link {
  display: flex;
  align-items: center;
  justify-content:space-between;
}
.forgot_message_ts {
  font-size: 0.875rem;
  color: #be3d63;
  cursor: pointer;
}

.form_grid_layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.mi_img {
  width: 40vw;
  height: 100%;
  display: flex;
  align-items: center;
}
.login_bigtitle_ts {
  font-size: 5rem;
  font-weight: 700;
  color: #fff;
}
.bigtext_wrap {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding-left: 5rem;
}
.form_system {
  padding: 3rem 3rem;
  background-color: rgba(255, 255, 255, 0.95);
}

.login_container {
  background: url("../../../assets/icons/images/form.png") center no-repeat;
  background-size: cover;
  border-radius: 0.45rem;
  position: relative;
}

.close_btn {
  width: 1.65rem;
  height: 1.65rem;
  border-radius: 1.65rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}
.close_btn > i {
  font-size: 1rem;
}

.input_password{
  position: relative;

}
.password_visible_icon{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  cursor: pointer;
}
.input_field.password_field{
  padding: 16px 42px 16px 16px !important;
}

.custom_error_text{
  color: red;
  font-size: 0.875rem;
  margin-top: 0.6rem;
}

.custom_error_text_alt{
  color: red;
  font-size: 0.875rem;
}

.error_area{
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.error_area > i{
  font-size: 1rem;
  color: red;
}

.button_text{
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 786px) {
  .form_grid_layout {
    display: grid;
    grid-template-columns: 1fr;
  }
  .form_banner {
    display: none;
  }
  .form_system {
    padding: 2rem 1.25rem;
    background-color: rgba(255, 255, 255, 1);
  }
}
