:root {
  --title_fs: 2.75rem;
  --heading_fs: 1.75rem;
  --subheading_fs: 1.35rem;
  --body_fs: 1rem;
  --note_fs: 0.935rem;
  --small_fs: 0.875rem;
  --tiny_fs: 0.625rem;
}

.mi_title {
  font-size: var(--title_fs);
}
.mi_heading {
  font-size: var(--heading_fs);
}
.mi_subheading {
  font-size: var(--subheading_fs);
}
.mi_body {
  font-size: var(--body_fs);
}
.mi_note {
  font: var(--note_fs);
}
.mi_small {
  font-size: var(--small_fs);
}
.mi_tiny {
  font-size: var(--tiny_fs);
}
@media screen and (max-width: 786px) {
  :root {
    --title_fs: 2.125rem;
    --heading_fs: 1.75rem;
    --subheading_fs: 1.35rem;
    --body_fs: 1rem;
    --note_fs: 0.935rem;
    --small_fs: 0.875rem;
    --tiny_fs: 0.625rem;
  }
}

@media screen and (max-width: 586px) {
  :root {
    --title_fs: 1.75rem;
    --heading_fs: 1.5rem;
    --subheading_fs: 1.35rem;
    --body_fs: 1rem;
    --note_fs: 0.935rem;
    --small_fs: 0.875rem;
    --tiny_fs: 0.625rem;
  }
}
