:root {
  --header_height: 4.25rem;
  --header_logo_size: 6.5rem;
  --item_gap_small: 2rem;
  --item_gap_medium: 2.5rem;
  --border_color: #92b3b3;

  --menu_dot_size: 0.5rem;
}
.header_null_space {
  width: 100%;
  height: var(--header_height);
}
.Header__main_container {
  width: 100%;
  height: var(--header_height);
  display: flex;
  background-color: var(--white_color);
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z_index_regular);
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  border-bottom: 1px solid #eee;
}
.Header__navbar_wrapper {
  width: 100%;
  height: var(--header_height);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Header__brand_logo {
  display: flex;
}
.Header__brand_logo > img {
  width: var(--header_logo_size);
  object-fit: contain;
}
.Header__menu_list {
  display: flex;
  align-items: center;
  gap: var(--item_gap_small);
}
.Header__menu_action_wrapper {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.Header__menu_item {
  display: flex;
}
.Header__nav_menu_container {
  display: flex;
  align-items: center;
  gap: var(--item_gap_medium);
}
.Header__hyperlink {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
}
.menu_link_dot {
  width: var(--menu_dot_size);
  height: var(--menu_dot_size);
  border-radius: var(--menu_dot_size);
  border: 2px solid var(--black_color);
  transition: all 0.3s ease-in-out;
}
.Header__menu_link_text {
  font-size: var(--small_fs);
  font-weight: 500;
  color: var(--black_color);
  transition: all 0.3s ease-in-out;
}
.Header__hyperlink:hover > .menu_link_dot {
  border: 2px solid var(--primary_color);
}
.Header__hyperlink:hover > .Header__menu_link_text {
  color: var(--primary_color);
}
.Header__hyperlink.link_active > .menu_link_dot {
  border: 2px solid var(--primary_color);
  background-color: var(--primary_color);
}
.Header__hyperlink.link_active > .Header__menu_link_text {
  color: var(--primary_color);
}

.Header__mobile_icon {
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 886px) {
  .Header__mobile_icon {
    cursor: pointer;
    display: flex;
  }
  .Header__menu_list {
    display: none;
  }
}

@media screen and (max-width: 586px) {
  .Header__menu_action_wrapper {
    display: none;
  }
}

.unify__mobile_menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.unify__mobile_menu.show__mobile_menu {
  right: 0;
  opacity: 1;
  visibility: visible;
}
.unify__mobile_wrapper {
  display: flex;
  flex-direction: column;
}

.mobile_wrapper_header {
  width: 100%;
  padding: 0rem 1.5rem;
  height: var(--header_height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}
.mobile_headline_text {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--black_color);
  text-transform: uppercase;
}
.Header__mobile_close_icon {
  cursor: pointer;
}
.mobile_wrapper_body {
  display: flex;
  flex-direction: column;
}
.mobile__nav_link_card {
  width: 100%;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.mobile_navlink_text {
  font-size: var(--body_fs);
  font-weight: 500;
  color: var(--black_color);
  text-transform: uppercase;
}

.Header__mobile_menu_action_wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}
