.popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 4rem;
}
.success-icon {
  font-size: 5rem;
}
