.HomeFooter__main_container {
  background-color: var(--black_color);
}
.HomeFooter__layout_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.HomeFooter__footer_brand {
  display: flex;
}
.HomeFooter__footer_brand > img {
  width: var(--header_logo_size);
  object-fit: contain;
}

.copyright_text {
  font-size: 0.75rem;
  color: var(--white_color);
}

@media screen and (max-width: 586px) {
  .HomeFooter__footer_brand {
    display: none;
  }
}
