.Aboutus__main_container {
  background-color: var(--white_color);
}
.Aboutus__two_column_grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  align-items: center;
  gap: 10rem;
}

.Aboutus__content_area {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.Aboutus__image_area {
  width: 100%;
}
.Aboutus__image_area > img {
  width: 100%;
  object-fit: cover;
  border-radius: 0.6rem;
}

.Aboutus__body_text {
  line-height: 1.85rem;
  color: #525560;
}

.Aboutus__action_wrapper {
  margin-top: 1rem;
}

@media screen and (max-width: 786px) {
  .Aboutus__two_column_grid {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 3rem;
  }
}
