// Globle Imports
@import "../../assets/styles/globle.scss";

// Header Styles
#header {
  grid-area: Navbar;
  width: 100%;
  height: 4rem;
  // background: $Brand-Blue;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000 !important;
}

.main-navbar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  padding: 0 1.5rem 0 0.8rem;
}

.brand-logo {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}
.brand-link {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}
.logo-sub-text {
  font-size: 0.8rem;
  padding-left: 0.3rem;
  color: #333;
}
.brand-logo-image {
  width: 8rem;
  object-fit: contain;
}

.mobile-view {
  display: none;
}
.mobile-sidebar {
  display: none;
}
.icons {
  font-size: 1.8rem !important;
  cursor: pointer;
  color: $Blue-Bold;
}

.main-nav-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0 !important;
}

.main-nav-items {
  list-style: none;
  margin: 0 1rem;
}

.main-nav-items:last-child {
  margin-right: 0 !important;
}

.profile-avatar {
  cursor: pointer;
}

.SearchBar {
  width: 300px;
  border: 1px solid #ffa83b;
  border-radius: 6px;
}

.profile-container {
  position: relative;
}
.profile-drop {
  display: block;
  position: absolute;
  right: 0;
  top: 3rem;
  width: 15rem;
  height: 18rem;
  border-radius: 0.4rem;
  background: #fff;
  border: 1px solid #ddd;
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
}
.active-ss {
  visibility: hidden;
  opacity: 0;
}
.profile-card {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.5rem;
}
.profile-cell-links {
  width: 100%;
  margin: 1rem 0;
}
.profile-card h1 {
  font-size: 2rem;
  color: #333;
}

.mobile-nav {
  position: fixed;
  top: 0;
  right: -100%;
  width: 17rem;
  height: 100vh;
  background: #fff;
  transition: all 0.5s !important;
  display: block;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
  padding: 0 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  visibility: hidden;
  opacity: 0;
}
.mobile-nav-active {
  right: 0;
  visibility: visible;
  opacity: 1;
}
.close-icon {
  color: #333;
  font-size: 1.7rem;
  cursor: pointer;
}
.search-icon {
  color: #333;
  font-size: 1.3rem;
  cursor: pointer;
}

.mobile-menu-header {
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
  justify-content: space-between;
}
.profile-cell {
  width: 100%;
  padding: 1rem 0.5rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #ddd;
  border-radius: 0.4rem;
}
.profile-avatar-mobile {
  width: 4rem !important;
  height: 4rem !important;
  object-fit: cover;
}
.username-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0.5rem;
}
.content-cell {
  width: 100%;
  padding: 0 0.4rem;
}
.sponsor-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #333 !important;
  margin-bottom: 2rem;
}
@media screen and (max-width: 768px) {
  .mobile-view {
    display: block;
  }
  .main-nav-list {
    display: none;
  }
}
@media screen and (max-width: 586px) {
  #header {
    grid-area: Navbar;
    height: 3rem;
  }
  .mobile-sidebar {
    display: block;
  }
  .icons {
    font-size: 1.3rem !important;
    cursor: pointer;
  }
  .logo-sub-text {
    font-size: 0.6rem;
    padding-left: 0.3rem;
    color: #333;
  }
  .brand-logo-image {
    object-fit: contain;
    height: 0.8rem;
  }
}
