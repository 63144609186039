.Blog__main_container {
  background-color: #fff;
}

.Blog__grid_layout {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 32.5rem;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  gap: 1.5rem;
  padding-top: 4rem;
  scroll-behavior: smooth;
}

/* .Blog__grid_layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  padding-top: 4rem;
} */

.Blog__grid_layout::-webkit-scrollbar {
  display: none;
}
.Blog__header_area {
  display: grid;
  grid-template-columns: 1fr 0.25fr;
  align-items: flex-end;
  gap: 5rem;
}
.scroll_button_group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.6rem;
}
.mi_scroll_button {
  display: flex;
}
.mi_scroll_button > i {
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--primary_color);
}

@media screen and (max-width: 786px) {
  .Blog__header_area {
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-end;
    gap: 0rem;
  }
  .scroll_button_group {
    display: none;
  }
}

@media screen and (max-width: 586px) {
  .Blog__grid_layout {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 20rem;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    gap: 1rem;
    padding-top: 4rem;
    scroll-behavior: smooth;
  }
}
