.page-heading-text {
  font-size: 1.6rem;
  color: $Black-Light;
  padding-bottom: 0.5rem;
  font-weight: 700 !important;
  border-bottom: 3px solid $Blue-Bold;
}

.indent-heading-text {
  font-size: 0.9rem;
  font-weight: 500;
  color: $Blue-Light;
}
.indent-card-heading-text {
  font-size: 0.9rem;
  font-weight: 500;
  color: $Blue-Light;
}
.indent-card-text {
  font-size: 0.8rem;
  color: $White-Bold;
}
.indent-card-text-black {
  font-size: 0.8rem;
  font-weight: 500;
  color: $Black-Bold;
}
.indent-card-sub-text {
  margin-top: 0.2rem;
  font-size: 0.7rem;
  color: $White-Bold;
}
.indent-card-text-bold {
  font-size: 0.8rem;
  font-weight: 700;
  color: $White-Bold;
}
.indent-card-text-md {
  font-size: 0.85rem;
  font-weight: 400;
  color: $Black-Bold;
}
.indent-card-text-md-bold {
  font-size: 0.85rem;
  font-weight: 500;
  color: $Black-Bold;
}
.indent-card-text-md-extrabold {
  font-size: 0.85rem;
  font-weight: 700;
  color: $Black-Bold;
}
.indent-card-data-text {
  width: 9rem;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.65rem 0.8rem;
  padding: 0.4rem 0.6rem;
  background: $White-Light;
  border: 1px solid $Blue-Mini;
  border-radius: 0.3rem;
}
.indent-card-data-text-md {
  width: 9rem;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.65rem 0.8rem;
  background: $White-Light;
  border: 1px solid $Blue-Mini;
  border-radius: 0.4rem;
  cursor: pointer;
}
.indent-card-total-text {
  font-size: 1.3rem;
  font-weight: 700;
  color: $Blue-Bold;
  margin-right: 1.8rem;
}
.tab-link-text {
  font-size: 1rem;
  font-weight: 500;
  color: $White-Bold;
  transition: 0.5s;
}
.tab-link-text-active {
  font-size: 1rem;
  font-weight: 500;
  color: $Blue-Bold;
}

.selected-row-text {
  font-size: 0.9rem;
  font-weight: 400;
  color: $Black-Medium;
}

// Tables
.table-heading-text {
  font-size: 0.9rem;
  color: $Blue-Light;
}
.table-heading-text-sm {
  font-size: 0.7rem;
  line-height: 1rem !important;
  color: $Blue-Light;
  padding: 0;
}
.table-subheading-text {
  font-size: 0.7rem;
}
.table-data-text {
  font-size: 0.75rem;
  color: $Black-Bold;
}
.table-data-sub-text {
  font-size: 0.6rem;
  color: $Black-Thin;
}
.text-bold {
  font-weight: 700 !important;
}
.text-pink {
  color: $Pink-Light;
}
.text-grey {
  color: $White-Bold;
}
.input-label-text {
  font-size: 0.9rem;
  font-weight: 400;
  color: $Blue-Light;
}

.input-error-text {
  font-size: 0.75rem;
  color: $Error;
}

// Filter-option-text
.filter-option-text {
  font-size: 0.8rem;
  color: $Blue-Light;
  transition: 0.5s;
}
.filter-option-text:hover {
  font-size: 0.8rem;
  color: $Black-Bold;
}

// Homepage Texts
.landing-large-text {
  font-size: 2.5rem;
  font-weight: 700;
  color: $Black-Medium;
}
.landing-large-sub-text {
  font-size: 1.2rem;
  font-weight: 500;
  color: $Black-Medium;
  margin-left: 0.3rem;
}
.homepage-heading-text {
  font-size: 2.5rem;
  font-weight: 700;
  color: $Pink-Medium;
}
.homepage-heading-text-sm {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  color: $Pink-Medium;
}
.homepage-sub-text {
  font-size: 1.1rem;
  color: $Black-Medium;
}
.role-text {
  font-size: 0.8rem;
  font-weight: 500;
  color: $Black-Medium;
  text-transform: uppercase;
  transition: 0.5s;
}

// Login Page
.login-heading-text {
  font-size: 1.6rem;
  font-weight: 700;
  color: $Black-Bold;
}

// Popup fonts
.popup-big-text {
  font-size: 2.5rem;
  font-weight: 700;
  color: $Black-Medium;
  margin-bottom: 0.7rem;
}
.popup-medium-text {
  font-size: 1rem;
}
.popup-small-text {
  font-size: 0.8rem;
  margin-top: 0.6rem;
  margin-bottom: 1rem;
}
.popup-small-text-bold {
  font-size: 0.9rem;
  color: $Black-Bold;
}

// Mobile-Menu
.organisation-name-text {
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}
.username-text {
  font-size: 0.9rem;
  font-weight: 400;
  color: #707070;
  margin-top: -0.2rem;
}
@media screen and (max-width: 586px) {
  .page-heading-text {
    font-size: 1.3rem;
    color: $Black-Light;
    padding-bottom: 0.3rem;
    font-weight: 700 !important;
    border-bottom: 2px solid $Blue-Bold;
  }
  .landing-large-text {
    font-size: 2rem;
    font-weight: 700;
    color: $Black-Medium;
  }
  .landing-large-sub-text {
    font-size: 0.8rem;
    font-weight: 500;
    color: $Black-Medium;
    margin-left: 0.3rem;
  }
}
