.footer-section {
  background: cadetblue;
  padding: 3rem 0;
}
.copy-section {
  background: cadetblue;
}
.footer-four-column-layout {
  display: grid;
  grid-template-columns: 1fr 1.2fr 1fr;
  gap: 5rem;
}
ul {
  margin-bottom: 0 !important;
}
li {
  list-style: none !important;
}
p {
  margin-bottom: 0 !important;
}
h1 {
  margin-bottom: 0 !important;
}
.footer-content-area {
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.company-logo-image {
  width: 15rem;
  object-fit: contain;
}

.mobile-app-stores {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.mobile-app-stores > a {
  width: 100%;
}

a > img {
  width: 100%;
}

.address-card {
  width: 100%;
  display: grid;
  grid-template-columns: 6rem 1fr;
  gap: 1rem;
}

.contact-card {
  width: 100%;
  display: grid;
  grid-template-columns: 6rem 1fr;
  gap: 1rem;
}

.contact-heading {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.contact-heading > i {
  color: #eee;
}

.contact-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.copy-rights {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
  border-top: 1px solid #ddd;
}

.social-media-list {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer-social-icon > i {
  font-size: 1.5rem;
  color: #eee;
  transition: all 0.3s ease-in-out;
}

.footer-social-icon > i:hover {
  color: #009ef7;
}

.footer_heading_ts {
  color: #111;
  font-size: 1.125rem;
  font-weight: 700;
}
.footer_subheading_ts {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
}
.footer_body_ts {
  font-size: 0.65rem;
  font-weight: 400;
  color: #fff;
}

@media screen and (max-width: 1264px) {
  .footer-four-column-layout {
    display: grid;
    grid-template-columns: 1fr 1.2fr 1fr;
    gap: 3rem;
  }
}

@media screen and (max-width: 992px) {
  .footer-four-column-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }
}

@media screen and (max-width: 786px) {
  .footer-four-column-layout {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .mobile-app-stores {
    width: 100%;
    max-width: 24rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}

@media screen and (max-width: 586px) {
  .footer-four-column-layout {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
