@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: #eee;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 30px;
}


/**/
.indent-card-data-text-md {
  width: 11rem !important;
}

.filter-icon {
  display: none !important;
}

.indent-card-data-text-md input,
.indent-card-data-text-md input:focus-visible,
.indent-card-data-text-md input:focus {
  border: 0 !important;
}

/* .report .select{
  width: 100%;
} */
.report .input-data select {
  width: 100%;
  padding: 0.5rem 0.4rem;
  /* outline: none; */
  /* border: none; */
  background: white;
  border-bottom: 2px solid #ddd;
  font-size: 0.9rem;
  border: 1px solid;
  border-radius: 4px;
  font-weight: 500;
  color: #6c78c3 !important;
  border-color: #b6b6fd;
}

.login-user-icons {
  width: 80px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline, .MuiInputBase-formControl fieldset {
  border: none;
  border-bottom: 1px solid #d2d2d2;
  border-radius: 0;
}