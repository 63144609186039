.Challenges__main_container {
  background-color: #fcedc6;
}
.Challenges__layout_wrapper {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.Challenges__section_title_tagline {
  max-width: 46rem;
}

.Challenges__card_list {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(30rem, 100%), 1fr));
  gap: 2rem;
}

.Challenge__counter_list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(20rem, 100%), 1fr));
  gap: 4.5rem;
}

@media screen and (max-width: 586px) {
  .Challenges__layout_wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  .Challenges__card_list {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
