.Support__main_container {
  background-color: #fff;
}
.Support__layout_wrapper {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.Support__points_card_list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(30rem, 100%), 1fr));
  gap: 3rem;
}
