// Globle Imports
@import "../globle.scss";

#page-container {
  grid-area: Content;
  width: 100%;
  padding: 1rem 1.5rem;
  height: 100%;
  overflow-x: hidden;
  background: $White-Medium;
}
.users-page-header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 0;
  align-items: center;
  justify-content: space-between;
}
.page-heading-btn-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.user-table-content {
  width: 100%;
  margin: 1rem 0;
  background: $White-Light;
}
.list-unstyled {
  margin-bottom: 0 !important;
}
.page-tab-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}
.tab-list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem 0;
  gap: 3rem;
}
.tab-item {
  list-style: none;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
}
.tab-item-active {
  list-style: none;
  cursor: pointer;
  position: relative;
}
.tab-item-active::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  width: 4rem;
  height: 0.25rem;
  border-radius: 0.3rem;
  background: $Orange-Bold;
}
.selected-row-counter {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 2.5rem;
}
