.Blogcard__wrapper {
  padding: 3rem;
  border-radius: 0.65rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.Blogcard__wrapper.b0 {
  background: linear-gradient(
      102.17deg,
      rgba(2, 0, 17, 0.76) 6.5%,
      rgba(23, 31, 65, 0.569182) 42.52%,
      rgba(21, 31, 66, 0.51) 52.61%,
      rgba(21, 34, 74, 0.604157) 61.37%,
      rgba(0, 10, 31, 0.8) 100%
    ),
    url("../../../../assets/icons/blog/blog1.jpg") center no-repeat;

  background-size: cover;
}
.Blogcard__wrapper.b1 {
  background: linear-gradient(
      102.17deg,
      rgba(2, 0, 17, 0.76) 6.5%,
      rgba(23, 31, 65, 0.569182) 42.52%,
      rgba(21, 31, 66, 0.51) 52.61%,
      rgba(21, 34, 74, 0.604157) 61.37%,
      rgba(0, 10, 31, 0.8) 100%
    ),
    url("../../../../assets/icons/blog/blog2.jpg") center no-repeat;
  background-size: cover;
}
.Blogcard__wrapper.b2 {
  background: linear-gradient(
      102.17deg,
      rgba(2, 0, 17, 0.76) 6.5%,
      rgba(23, 31, 65, 0.569182) 42.52%,
      rgba(21, 31, 66, 0.51) 52.61%,
      rgba(21, 34, 74, 0.604157) 61.37%,
      rgba(0, 10, 31, 0.8) 100%
    ),
    url("../../../../assets/icons/blog/blog3.jpg") center no-repeat;
  background-size: cover;
}
.Blogcard__content_wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Blogcard__action_wrap {
  display: flex;
}

@media screen and (max-width: 586px) {
  .Blogcard__wrapper {
    padding: 1.5rem;
    border-radius: 0.65rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
