.blog_card_wrapper {
  display: block;
}
.blog_card_inner_wrapper {
  display: grid;
  grid-template-columns: 1fr 1.75fr;
}
.blog_card_inner_wrapper_alt {
  display: grid;
  grid-template-columns: 1.75fr 1fr;
}
.blog_content_space {
  display: flex;
}

.blog_content_holder {
  padding: 3rem;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  z-index: 1;
  border-radius: 0.6rem;
}
.blog_card_wrapper1 {
  padding: 6rem 0;
  position: relative;
  margin: 2.5rem 0;
}
.blog_card_wrapper1::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 60%;
  height: 100%;
  background: url("../../../assets/icons/images/blog1.png") center no-repeat;
  background-size: cover;
}

.blog_card_wrapper2 {
  padding: 6rem 0;
  position: relative;
  margin: 5rem 0;
}
.blog_card_wrapper2::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 65%;
  height: 100%;
  background: url("../../../assets/icons/images/ngo.jpg") center no-repeat;
  background-size: cover;
}

.blog_card_wrapper3 {
  padding: 6rem 0;
  position: relative;
  margin: 5rem 0;
}
.blog_card_wrapper3::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 65%;
  height: 100%;
  background: url("../../../assets/icons/images/blog2.png") center no-repeat;
  background-size: cover;
}

.blog_card_wrapper4 {
  padding: 6rem 0;
  position: relative;
  margin: 5rem 0;
}
.blog_card_wrapper4::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 65%;
  height: 100%;
  background: url("../../../assets/icons/images/bank.jpg") center no-repeat;
  background-size: cover;
}

.blog_header {
  padding-top: 2rem;
  padding-bottom: 4rem;
}


.blog_alt_container{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  padding-bottom: 7rem;
}
.blog_alt_card{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1.5rem;
  border-radius: 0.6rem;
}
.blog_alt_banner{
  width: 100%;
  height: 12rem;
  border-radius: 0.6rem;
}
.blog_alt_banner.b1{
  background: url("../../../assets/icons/images/blog1.png") center no-repeat;
  background-size: cover;
}
.blog_alt_banner.b2{
  background: url("../../../assets/icons/images/ngo.jpg") center no-repeat;
  background-size: cover;
}
.blog_alt_banner.b3{
  background: url("../../../assets/icons/images/blog2.png") center no-repeat;
  background-size: cover;
}
.blog_alt_banner.b4{
  background: url("../../../assets/icons/images/bank.jpg") center no-repeat;
  background-size: cover;
}

.column_subheading_ts{
  font-size: 1.25rem;
  font-weight: 700;
}

@media screen and (max-width: 786px) {
  .blog_card_inner_wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }
  .blog_card_inner_wrapper_alt {
    display: grid;
    grid-template-columns: 1fr;
  }
  .blog_card_wrapper1 {
    padding: 0rem 0;
    position: relative;
    margin: 2.5rem 0;
  }

  .blog_card_wrapper2 {
    padding: 0rem 0;
    position: relative;
    margin: 2.5rem 0;
  }
  .blog_card_wrapper3 {
    padding: 0rem 0;
    position: relative;
    margin: 2.5rem 0;
  }
  .blog_card_wrapper4 {
    padding: 0rem 0;
    position: relative;
    margin: 2.5rem 0;
  }
 
  .blog_card_wrapper::before {
    display: none;
  }

  .blog_card_wrapper_alt::before {
    display: none;
  }
  .blog_alt_container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding-bottom: 5rem;
  }
}

@media screen and (max-width: 586px) {
  .blog_alt_container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    padding-bottom: 3rem;
  }
}