:root {
  --icon_wrap_size: 2.25rem;
  --icon_ico_size: 1rem;
  --icon_bg_color: var(--primary_color);
  --icon_color: var(--white_color);
}

.Iconcard__wrapper {
  display: grid;
  grid-template-columns: var(--icon_wrap_size) 1fr;
  gap: 1rem;
}
.Iconcard__icon {
  width: var(--icon_wrap_size);
  height: var(--icon_wrap_size);
  border-radius: 0.45rem;
  background-color: var(--icon_bg_color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.Iconcard__icon > i {
  font-size: var(--icon_ico_size);
  color: var(--icon_color);
}
.Iconcard__details {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
.Iconcard__title_text {
  font-size: var(--subheading_fs);
  font-weight: 600;
  color: var(--black_color);
}
.Iconcard__message_text {
  font-size: var(--body_fs);
  font-weight: 400;
  color: var(--grey_color);
  line-height: 1.85rem;
}

@media screen and (max-width: 420px) {
  :root {
    --icon_wrap_size: 3.25rem;
    --icon_ico_size: 1.5rem;
  }
  .Iconcard__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .Iconcard__message_text {
    font-size: var(--body_fs);
    font-weight: 400;
    color: var(--grey_color);
    line-height: 1.65rem;
  }
}
