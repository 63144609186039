/* Partner Alternative */
.Partner__layout_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.client_list_first_row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 2rem;
}
.client_list_second_row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 2rem;
}
.client_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.client_wrap > img {
  width: 100%;
  max-width: 6rem;
  filter: grayscale(100%);
  opacity: 0.7;
}

.message_heading {
  display: grid;
  grid-template-columns: 10rem 1fr;
  align-items: center;
  gap: 1rem;
}

.section_tagline_ts {
  font-size: var(--note_fs);
  font-weight: 600;
  text-transform: uppercase;
  color: #1d2130;
}

@media screen and (max-width: 586px) {
  .client_list_first_row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
  .client_list_second_row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}

.Partner__main_container {
  background-color: #fff;
  padding-bottom: 5rem;
}

@media screen and (max-width: 786px) {
  .Partner__main_container {
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 586px) {
  .Partner__main_container {
    padding-bottom: 3.5rem;
  }
}
