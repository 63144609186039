.Features__main_container {
  background-color: #fcedc6;
}
.Features__layout_wrapper {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.Feature__section_title_tagline {
  max-width: 60rem;
}

.Features__image_area {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Features__image_area > img {
  width: 100%;
  height: 18rem;
  object-fit: cover;
  border-radius: 0.6rem;
}

.Features__two_column_layout {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  align-items: center;
  gap: 5rem;
}

.Feature__points_list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media screen and (max-width: 586px) {
  .Features__two_column_layout {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 3rem;
  }
  .Features__layout_wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  .Feature__points_list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0 0.5rem;
  }
}
