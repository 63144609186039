.blog_body_section {
  padding: 3rem 0;
}
.BlogPost__two_column_layout {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 3rem;
}

.blog_more_details_wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;
}
.dot_divider {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 0.4rem;
  background-color: rgba(23, 43, 77, 0.6);
}
.white_dot {
  background-color: #fff !important;
}
.black_dot {
  background-color: #333 !important;
}
.blog_headline_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.media_headline_wrapper {
  display: flex;
  max-width: 90%;
  flex-direction: column;
  gap: 1rem;
}
.blog_headline_image {
  width: 100%;
  height: 60vh;
  margin-top: 2rem;
}
.blog_headline_image.b0 {
  background: url("../../../assets/icons/blog/blog1.jpg") no-repeat;
  background-position: center;
  background-size: cover;
}
.blog_headline_image.b1 {
  background: url("../../../assets/icons/blog/blog2.jpg") no-repeat;
  background-position: top center;
  background-size: cover;
}
.blog_headline_image.b2 {
  background: url("../../../assets/icons/blog/blog3.jpg") no-repeat;
  background-position: top center;
  background-size: cover;
}
.image_highlight_holder {
  width: 100%;
  height: 50vh;
  background-color: #11ce8e;
}

.image_highlight > img {
  width: 100%;
}

.image_highlight {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.image_info_ts {
  color: var(--font_black);
  font-weight: 300;
}

.related_post_card {
  display: grid;
  grid-template-columns: 8rem 1fr;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.blog_related_card_container {
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
}

.related_post_image_holder {
  width: 100%;
  height: 5rem;
}

.related_post_image_holder.b0 {
  background: linear-gradient(
      102.17deg,
      rgba(2, 0, 17, 0.76) 6.5%,
      rgba(23, 31, 65, 0.569182) 42.52%,
      rgba(21, 31, 66, 0.51) 52.61%,
      rgba(21, 34, 74, 0.604157) 61.37%,
      rgba(0, 10, 31, 0.8) 100%
    ),
    url("../../../assets/icons/blog/blog1.jpg") no-repeat;
  background-position: top center;
  background-size: cover;
}
.related_post_image_holder.b1 {
  background: linear-gradient(
      102.17deg,
      rgba(2, 0, 17, 0.76) 6.5%,
      rgba(23, 31, 65, 0.569182) 42.52%,
      rgba(21, 31, 66, 0.51) 52.61%,
      rgba(21, 34, 74, 0.604157) 61.37%,
      rgba(0, 10, 31, 0.8) 100%
    ),
    url("../../../assets/icons/blog/blog2.jpg") no-repeat;
  background-position: top center;
  background-size: cover;
}
.related_post_image_holder.b2 {
  background: linear-gradient(
      102.17deg,
      rgba(2, 0, 17, 0.76) 6.5%,
      rgba(23, 31, 65, 0.569182) 42.52%,
      rgba(21, 31, 66, 0.51) 52.61%,
      rgba(21, 34, 74, 0.604157) 61.37%,
      rgba(0, 10, 31, 0.8) 100%
    ),
    url("../../../assets/icons/blog/blog3.jpg") no-repeat;
  background-position: top center;
  background-size: cover;
}
.related_content_space {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.blog_full_details {
  padding: 0rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
}
.blog_full_details::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 6px;
  height: 3rem;
  background-color: #111;
}

/*Typography Design */
.blog_headline_ts {
  color: var(--font_black);
  font-weight: 600;
}

.blog_note_ts {
  color: var(--font_slate);
}

.media_subheading_ts {
  color: var(--font_grey);
  text-align: justify;
  line-height: 2rem;
}
.related_blog_tag_ts {
  font-size: 0.8rem;
  color: var(--font_slate);
  font-weight: 300;
}
.related_blog_title_ts {
  font-size: 0.875rem;
  color: var(--font_black);
  font-weight: 400;
}

.related_blog_heading_ts {
  font-size: 1.125rem;
  color: var(--font_black);
  font-weight: 600;
}

.social_link_text {
  color: #136aff;
}

.media_external_link {
  font-weight: 500 !important;
  color: #9754e9 !important;
  text-decoration: underline !important;
}

@media screen and (max-width: 786px) {
  .BlogPost__two_column_layout {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}

@media screen and (max-width: 586px) {
  .BlogPost__two_column_layout {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  .blog_full_details {
    padding: 0rem 0rem;
  }
  .blog_full_details::before {
    display: none;
  }
}

/* My Class */
.BlogPost__main_container {
  background-color: #fff !important;
}
.BlogPost__layout_wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.site_subheading_ts {
  font-size: 1.45rem !important;
  color: #333;
}

.paragraph_points_list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 2rem;
}

.paragraph_points_list > p > span {
  font-size: 1.15rem;
  font-weight: 600;
  color: #111 !important;
}

.blog_video_container > iframe {
  width: 100%;
  aspect-ratio: 16/9;
  height: auto;
}

.site_bold_text_ts {
  font-size: 1.15rem;
  font-weight: 600;
  color: #333333 !important;
}
