.breadcrumb_link_container {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem 0;
}

.breadcrumb_body_ts {
  font-size: 0.825rem;
  font-weight: 300;
  color: var(--font_dark);
}

.breadcrumb_active > .breadcrumb_body_ts {
  font-size: 0.825rem;
  font-weight: 500;
  color: var(--font_black);
}

@media screen and (max-width: 586px) {
  .breadcrumb_link_container {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    padding: 1.5rem 0;
  }
  .breadcrumb_body_ts {
    font-size: 0.775rem;
    font-weight: 300;
    color: var(--font_dark);
  }

  .breadcrumb_active > .breadcrumb_body_ts {
    font-size: 0.775rem;
    font-weight: 500;
    color: var(--font_black);
  }
}
