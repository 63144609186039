.Countercard__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Countercard__count {
  display: flex;
  align-items: baseline;
  gap: 1rem;
}
.Countercard__count_text {
  font-size: var(--title_fs);
  font-weight: 700;
  color: var(--primary_color);
}
.Countercard__unit_text {
  font-size: var(--note_fs);
  font-weight: 600;
  color: var(--black_color);
  margin-top: -1rem;
}
.Countercard__info {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
