:root {
  --primary_color: #e0551e;
  --secondary_color: #20232b;
  --theme_primary_color: #cfe7de;
  --white_color: #fff;
  --black_color: #1d2130;
  --grey_color: #525560;

  --icon_size_regular: 1.125rem;
  --z_index_regular: 100;
}
