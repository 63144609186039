:root {
  --bs_primary: #e0551e;
  --bs_secondary: #e9ecf2;
  --bs_primary_active: #f76a33;
  --bs_secondary_active: #e0551e;
  --bs_btn_black: #4b5675;
  --bs_btn_white: #ffffff;
}

.mi_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  font-family: var(--theme_font_family);
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.mi_btn_medium {
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding: 0.65rem 1.25rem;
}

.mi_btn_large {
  font-size: 1rem;
  line-height: 1.125rem;
  padding: 0.8rem 1.5rem;
}

.mi_btn_medium > i {
  font-size: 1rem;
  color: inherit;
}

.mi_btn_large > i {
  font-size: 1.125rem;
  color: inherit;
}

/* Button Colors */

.mi_btn_primary {
  background-color: var(--bs_primary) !important;
  border: 2px solid var(--bs_primary) !important;
  color: #fff !important;
}

.mi_btn_primary:hover {
  background-color: var(--bs_primary_active) !important;
  border: 2px solid var(--bs_primary_active) !important;
  color: #fff !important;
}

.mi_btn_secondary {
  background-color: var(--bs_secondary) !important;
  border: 2px solid var(--bs_secondary) !important;
  color: var(--bs_btn_black) !important;
}

.mi_btn_secondary:hover {
  background-color: var(--bs_secondary_active) !important;
  border: 2px solid var(--bs_secondary_active) !important;
  color: #fff !important;
}

.mi_btn_outline {
  background-color: transparent !important;
  border: 2px solid var(--bs_primary) !important;
  color: var(--bs_primary) !important;
}

.mi_btn_outline:hover {
  background-color: var(--bs_primary) !important;
  border: 2px solid var(--bs_primary) !important;
  color: #fff !important;
}
