// Globle Imports
@import "../globle.scss";

.user-table-grid-card {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5rem;
}
.mb-medium {
  margin-bottom: 0.6rem;
}
.center-card {
  background: $White-Light;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
  border-radius: 0.4rem;
  padding: 1rem;
}
.mandal-card {
  background: $White-Light;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
  border-radius: 0.4rem;
  padding: 1rem;
}
.aww-card {
  background: $White-Light;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
  border-radius: 0.4rem;
  padding: 1rem;
}
@media screen and (max-width: 1086px) {
  .user-table-grid-card {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  }
}
@media screen and (max-width: 786px) {
  .user-table-grid-card {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
  }
}
