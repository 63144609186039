// Globle Imports
@import "../globle.scss";

// UserTable Styles
.table-head-wrapper {
  border-right: 1px solid $White-Semibold !important;
}

// MUI TABLE STYLE
.css-1ex1afd-MuiTableCell-root {
  padding: 12px !important;
}
.table-head-wrapper-border-less {
  border-right: unset !important;
}

.table-collapse-data {
  padding-bottom: 1rem;
}
.table-flex-center {
  display: flex;
  align-items: center;
}
.gap-sm {
  gap: 1rem;
}
.gap-xl {
  gap: 4rem;
}
.filter-icon {
  transform: rotate(90deg);
  font-size: 0.9rem !important;
  cursor: pointer;
  margin-left: 1rem;
  color: $Blue-Medium;
}
.filter-none {
  outline: none !important;
}
.filter-none:focus {
  outline: none !important;
  border-color: none;
  box-shadow: none;
  margin-bottom: 0.5rem !important;
}
.table-flex-column {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0.2rem;
}
.table-flex-column-right {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 1rem;
}
.table-flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table-flex-space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-flex-even {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.table-profile-pic {
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 0.3rem;
  background: $Brand-Blue;
  margin-right: 0.6rem;
}
.table-edit-icon {
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 0.2rem;
  background: $Brand-Yellow;
  margin-right: 0.6rem;
}
.accountText {
  position: relative;
  left: -10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
  margin-left: 10px !important;
  margin-bottom: 0 !important;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffa83b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffa83b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}

// Filter Dropdown
.dropdown-list {
  margin: 0.5rem 0 !important;
  height: 8rem;
  overflow-y: scroll;
  scrollbar-width: thin !important;
  z-index: -1 !important;
}
.menu-list {
  padding: 0 0.5rem;
}
.filter-search {
  width: 100%;
  margin-bottom: 0.2rem !important;
}

// Select Edit
.inline-select {
  width: 6.5rem !important;
  padding: 6.7px 0 !important;
  outline: none;
  border: 1px solid #fff !important;
  border-radius: 0.4rem;
  position: relative;
}
.inline-select-border {
  border: 1px solid $Blue-Thin !important;
}
.inline-select-icon {
  border: 1px solid #fff !important;
}
.margin-left-inline {
  width: 6.5rem;
  padding: 8px 0 !important;
  padding-left: 0.3rem !important;
}

// Collaspe Grid
.collapse-table-grid {
  display: grid;
  grid-template-columns: 2fr 2fr 0.5fr;
  padding-bottom: 1rem;
}
.collapse-grid-data-group {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  padding: 0.3rem 0;
}
.align-c {
  align-items: center;
}
.input-edit-text-collapse {
  width: 10rem;
  padding: 0.3rem 0.8rem;
  border-radius: 0.5rem;
  border: 1px solid #fff;
  outline: none;
}
.input-edit-active {
  border: 1px solid #c7d7f7;
  outline: none;
}
input:disabled {
  cursor: default;
  background-color: #fff;
}
.collapse-btn-area {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.inline-address-text {
  width: 15.5rem;
  padding-left: 0.8rem;
}
.collapse-btn {
  margin-top: 0.3rem;
}
.select-text {
  width: 7rem;
  padding: 0.2rem 0.3rem;
  border: 1px solid #fff;
  border-radius: 0.4rem;
  position: relative;
}
.select-text-border {
  border: 1px solid $Blue-Thin;
}
.drop-icons-none {
  display: none !important;
}
.drop-icons {
  display: block;
  font-size: 0.8rem !important;
  position: absolute;
  top: 5px;
  right: 3px;
  transform: rotate(90deg);
}

.table-status-icons {
  font-size: 1.5rem;
}
.table-check-icons {
  font-size: 1rem;
  margin-left: 0.5rem;
}
.icon-available {
  color: $Pass;
}
.icon-not-available {
  color: $Error-light;
}
.icon-doubt {
  color: $Orange-Medium;
}
