// Globle Imports
@import "../globle.scss";

// User Styles
.user-content-container {
  width: 100%;
  background: $White-Light;
  margin: 1.5rem 0;
  min-height: calc(70vh - 1.5rem);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
  border-radius: 0.4rem;
  display: grid;
  grid-template-columns: 2fr 1.5fr;
  padding: 1.5rem;
}
.input-group-grid {
  display: grid;
  grid-template-columns: 1.2fr 2fr;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.6rem;
}
.input-data {
  position: relative;
}
.error-text {
  position: absolute;
  left: 0.3rem;
  bottom: -22px;
}
.input-data input {
  width: 100%;
  padding: 0.3rem 0.5rem;
  outline: none;
  border: none;
  background: transparent;
  border-bottom: 2px solid #ddd;
  font-size: 0.9rem;
}
.input-data select {
  width: 100%;
  padding: 0.3rem 0.3rem;
  outline: none;
  border: none;
  background: transparent;
  border-bottom: 2px solid #ddd;
  font-size: 0.9rem;
}
.button-group-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.button-group {
  display: flex;
  align-items: center;
  gap: 2rem;
}
select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}

.sector-button {
  margin-bottom: 0.8rem;
  text-align: right;
}

@media screen and (max-width: 1110px) {
  .user-content-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1.5rem;
    grid-gap: 1rem;
  }
  .button-group-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 586px) {
  .input-group-grid {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    margin-top: 0.2rem;
    padding-bottom: 1.6rem;
  }
}
@media screen and (max-width: 520px) {
  .button-group {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}
