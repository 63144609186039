.Reachout__main_container {
  background-color: #fff;
}
.contact_us_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
}
.contact_us_content {
  display: flex;
}
.contact_headline {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact_subheading_ts {
  font-size: 1.125rem;
  font-weight: 400;
  color: rgb(75 75 75);
  line-height: 2.25rem;
}

/* Form Styles */

.input_space {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.input_label {
  font-size: max(0.9722222222vw, 12px);
  line-height: 1.2;
  font-weight: 700;
  color: rgb(36 32 33);
  display: flex;
  gap: 0.25rem;
}
.label_text {
  font-size: max(0.9722222222vw, 12px);
  line-height: 1.2;
  font-weight: 700;
  color: rgb(36 32 33);
}
.important_sign {
  color: rgba(253 46 25/1);
}
.input_field {
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  color: rgb(36 32 33);
  resize: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  font-size: max(0.9722222222vw, 12px);
  line-height: 1.6;
  font-weight: 400;
}
.site_contact_form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.input_two_column {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(16rem, 100%), 1fr));
  gap: 1.5rem;
}

.input_wrap select {
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  padding-bottom: 12px;
  background-image: url("../../../assets/icons/svgs/down.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;
  background-size: 24px 24px;
  color: rgba(36 32 33 / 0.6);
}

.input_wrap select {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  color: rgb(36 32 33);
  resize: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  font-size: max(0.9722222222vw, 12px);
  line-height: 1.6;
  font-weight: 400;
}

.input_wrap textarea {
  height: 98px;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  color: rgb(36 32 33);
  resize: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  font-size: max(0.9722222222vw, 12px);
  line-height: 1.6;
  font-weight: 400;
}

.terms_body_ts {
  font-size: max(0.9722222222vw, 12px);
  line-height: 1.6;
  font-weight: 400;
  word-break: break-word;
}

.inputs-list label {
  display: flex;
  align-items: center;
  font-size: max(0.6944444444vw, 12px);
  cursor: pointer;
}

.inputs-list label input {
  position: relative;
  margin-right: max(0.5555555556vw, 8px);
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none !important;
  cursor: pointer;
}

.inputs-list label input:checked {
  color: rgb(52, 52, 58);
  outline: none;
  border-radius: 3px;
  background-color: rgb(17 143 109);
  border-color: rgb(17 143 109);
  width: 24px;
  height: 24px;
}

.inputs-list label input::before {
  content: "";
  color: #fff;
  background: url("../../../assets/icons/svgs/check.svg") no-repeat center
    center;
  display: block;
  width: 24px;
  height: 24px;
}

.inputs-list label span {
  flex-grow: 1;
  margin: 0;
  font-weight: 600;
}

.inputs-list label span,
#hbspot-form label {
  font-size: max(0.9722222222vw, 12px);
  line-height: 1.2;
  font-weight: 700;
  color: rgb(36 32 33);
}
.form_action {
  margin-top: 1rem;
}
.form_action > a {
  display: inline-flex;
}

@media screen and (max-width: 786px) {
  .contact_us {
    padding: 3rem 0 5rem 0;
  }
  .contact_us_grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4rem;
  }
  .contact_subheading_ts {
    font-size: 1rem;
    font-weight: 400;
    color: rgb(75 75 75);
    line-height: 2.25rem;
  }
}

@media screen and (max-width: 586px) {
  .contact_us {
    padding: 1rem 0 3rem 0;
  }
  .contact_us_grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  .contact_subheading_ts {
    font-size: 1rem;
    font-weight: 400;
    color: rgb(75 75 75);
    line-height: 2.25rem;
  }
  .contact_headline {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }
}

@media screen and (max-width: 420px) {
  .contact_us {
    padding: 1rem 0 3rem 0;
  }
  .contact_us_grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  .contact_subheading_ts {
    font-size: 0.875rem;
  }
  .contact_headline {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }
}
