// Globle Imports
@import "../../assets/styles/globle.scss";

.landing-container {
  width: 100%;
  // height: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.container-center-wrap {
  width: 75%;
  height: 100%;
  display: block;
  margin: 0 auto;
  padding: 0 1.5rem;
  position: relative;
}
.landing-page-box {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  grid-gap: 3rem;
  padding: 1.5rem;
}
.user-content-area {
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.landing-brand-logo {
  width: 22rem;
}
.select-text-group {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.user-content-area {
  width: 100%;
  height: 100%;
}
.user-selection-area {
  width: 100%;
  height: 100%;
}
.user-selection-container {
  background: #fff;
  width: 100%;
  height: 100%;
  min-height: 70vh;
  border-radius: 0.6rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
  //   padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}
.select-role-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.role {
  width: 8rem;
  height: 8rem;
  border-radius: 0.6rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  cursor: pointer;
  transition: 0.5s;
}
.role:hover {
  background: $Blue-Mini;
}
.role-active {
  background: $Blue-Mini;
}
.icon-role {
  font-size: 4rem;
  color: #999;
  transition: 0.5s;
}
.role-icon-active {
  color: $Blue-Bold;
}

// LOGIN
.login-field {
  width: 70%;
}
.login-input-group {
  position: relative;
  padding-bottom: 1.2rem;
}
.login-action-group {
  padding-left: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}

@media screen and (max-width: 786px) {
  .landing-page-box {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .landing-brand-logo {
    width: 20rem;
  }
}
@media screen and (max-width: 586px) {
  .landing-container {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .landing-page-box {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .landing-brand-logo {
    width: 15rem;
  }
}
