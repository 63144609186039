:root {
  --header_height: 4.5rem;
}
.site_header {
  width: 100%;
  z-index: 1000;
  transition: all 300ms ease-in-out;
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.mi_page_container {
  display: block;
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.25rem;
}
.site_navbar {
  width: 100%;
  height: var(--header_height);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.brand_logo > img {
  width: 10rem;
  object-fit: contain;
}
.site_menulist {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-bottom: 0 !important;
}
.site_menuItem {
  list-style: none;
}
.site_menuLink {
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--font_dark) !important;
  cursor: pointer;
}

.header_action {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  padding-left: 1.5rem;
}

.mobile_btns {
  display: none;
}

@media screen and (max-width: 992px) {
  .site_menulist {
    display: none;
  }
  .mobile_btns {
    display: flex;
  }
}

@media screen and (max-width: 586px) {
  .site_menulist {
    display: none;
  }
  .mobile_btns {
    display: none;
  }
}
