.SectionTitle__tagline_text {
  font-size: var(--note_fs);
  font-weight: 600;
  text-transform: uppercase;
  color: #1d2130;
  position: relative;
}
.SectionTitle__title_text {
  font-size: var(--title_fs);
  font-weight: 700;
}
.SectionTitle__title_text.plain_color {
  color: #1d2130;
}
.SectionTitle__title_text.white_color {
  color: #fff;
}
.SectionTitle__tagline_text::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -4rem;
  width: 3rem;
  height: 2px;
  background-color: #1d2130;
}

.SectionTitle__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media screen and (max-width: 586px) {
  .SectionTitle__tagline_text {
    padding-left: 4rem;
  }
  .SectionTitle__tagline_text::before {
    left: 0rem;
  }
}
