@import "variables/color";
@import "variables/buttons";
@import "variables/text";

// Page Header
body {
  background: $White-Medium;
}
.css-1ygcj2i-MuiTableCell-root {
  padding: 12px !important;
}
.link-none {
  text-decoration: none;
}
.icons-class {
  font-size: 1rem;
}
.icons-btn {
  font-size: 1rem;
  margin-right: 1rem;
}
.icons-btn-sm {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.icons-btn-l {
  font-size: 1rem;
  margin-left: 1rem;
}
.icons-btn-sm-l {
  font-size: 1rem;
  margin-left: 0.5rem;
}
.cursor-none {
  cursor: default;
}
.ml-70 {
  margin-left: 0.7rem;
}
.mr-70 {
  margin-left: 0.7rem;
}
.w-1 {
  width: 1rem !important;
}
.w-2 {
  width: 2rem !important;
}
.w-3 {
  width: 3rem !important;
}
.w-4 {
  width: 4rem !important;
}
.w-35 {
  width: 3.4rem !important;
}
.w-5 {
  width: 5rem !important;
}
.w-55 {
  width: 5.5rem !important;
}
.w-6 {
  width: 6rem !important;
}
.w-7 {
  width: 7rem !important;
}
.w-8 {
  width: 8rem !important;
}
