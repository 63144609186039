// Common Btn Class
.btn {
  border: none;
  outline: none;
  padding: 0.5rem 2.45rem;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 0.4rem;
  transition: 0.5s;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 1;
}
.btn:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 12px;
}
.btn-disable {
  opacity: 0.4;
  cursor: pointer;
}
.btn-alt {
  border: none;
  outline: none;
  padding: 0.5rem;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 0.4rem;
  transition: 0.5s;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 1;
}
.btn-link-view {
  background: transparent;
  border: none;
  outline: none;
  color: $Pink-Bold;
  text-decoration: none;
}

.btn-small {
  border: none;
  outline: none;
  padding: 0.2rem 1.5rem;
  font-size: 0.7rem;
  border-radius: 0.4rem;
  transition: 0.5s;
  cursor: pointer;
}
.btn-mini {
  border: none;
  outline: none;
  padding: 0.35rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 0.4rem;
  transition: 0.5s;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 1;
}
.btn-medium {
  border: none;
  outline: none;
  padding: 0.35rem 1.5rem;
  font-size: 0.7rem;
  border-radius: 0.4rem;
  transition: 0.5s;
  cursor: pointer;
}

// Button Blue
.btn-blue {
  border: 2px solid $Blue-Medium;
  background: $Blue-Medium;
  color: $White-Light;
}
.btn-blue:hover {
  color: $White-Light;
}

.btn-blue-outline {
  color: $Blue-Medium;
  border: 2px solid $Blue-Medium;
}

.btn-blue-outline-hover {
  color: $Blue-Medium;
  border: 2px solid $Blue-Medium;
}
.btn-blue-outline-hover:hover {
  background: $Blue-Medium;
  color: $White-Light;
  border: 2px solid $Blue-Medium;
}

// Button Blue
.btn-varblue {
  border: 2px solid $Blue-Mini;
  background: $Blue-Mini;
  color: $Blue-Bold;
}
.btn-varblue:hover {
  border: 2px solid $Blue-Bold;
  background: $Blue-Bold;
  color: $White-Light;
}
.btn-varblue-outline {
  color: $Blue-Mini;
  border: 2px solid $Blue-Mini;
}

.btn-hidden {
  border: 2px solid $White-Light;
  background: $White-Light;
  color: $White-Light;
  cursor: default;
  visibility: hidden;
}
.btn-hidden:hover {
  box-shadow: none;
}
.btn-center-link {
  width: 14rem;
  text-align: center;
}
// Button Orange
.btn-orange {
  border: 2px solid $Orange-Bold;
  background: $Orange-Bold;
  color: $White-Light;
}
.btn-orange:hover {
  color: $White-Light;
}

.btn-orange-outline {
  color: $Orange-Bold;
  border: 2px solid $Orange-Bold;
}

// Button Orange
.btn-yellow {
  border: 2px solid $Orange-Light;
  background: $Orange-Light;
  color: $White-Light;
}
.btn-yellow:hover {
  color: $White-Light;
}

.btn-yellow-outline {
  color: $Orange-Light;
  border: 2px solid $Orange-Light;
}

// Button Pink
.btn-pink {
  border: 2px solid $Pink-Bold;
  background: $Pink-Bold;
  color: $White-Light;
}

.btn-popup {
  margin: 1rem;
}

.btn-pink:hover {
  color: $White-Light;
}

.btn-pink-outline {
  color: $Pink-Bold;
  border: 2px solid $Pink-Bold;
}

// Button Lightblue
.btn-lightblue {
  border: 2px solid $Blue-Semibold;
  background: $Blue-Semibold;
  color: $White-Light;
}
.btn-lightblue:hover {
  color: $White-Light;
}

.btn-lightblue-outline {
  color: $Orange-Light;
  border: 2px solid $Blue-Semibold;
}

// Dropdown list btn
.drop-btn {
  display: block;
  width: 100%;
  font-size: 0.7rem;
  padding: 0.5rem 1rem;
  border: 1px solid $Blue-Medium;
  color: $Blue-Medium;
}
.drop-btn:hover {
  background: $Blue-Medium;
  color: $White-Light;
}
.drop-btn:first-child {
  margin-bottom: 0.3rem !important;
}

// Dropdow btn Reset
.btn:focus {
  box-shadow: none !important;
}
.dropdown-item:hover {
  background-color: unset !important;
  color: unset !important;
}
