.About_container {
  background-color: var(--brand_blue);
}
.about_inner_container {
  padding: 2.5rem 0;
}
.mi_two_column_md {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 6.5rem;
  padding: 2.5rem;
}
.mi_two_column_xl {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6.5rem;
  padding: 2.5rem;
}

.column_image {
  width: 100%;
}
.column_image > img {
  width: 100%;
}
.column_content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.column_heading_ts {
  font-size: 2rem;
  font-weight: 700;
  color: #be3d63;
}
.column_body_ts {
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--font_grey);
  line-height: 2.25rem;
  margin-bottom: 0 !important;
}

.paragraph_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mi_align_center {
  align-items: center;
}

.section_headline_ts {
  font-weight: 700;
  color: #111;
}

.about_header {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.section_headline {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.column_content_alt >  h1{
  padding-bottom: 1.25rem !important;
}

.about_headline_grid{
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  gap: 5rem;
  padding: 2.5rem;
}
.about_img{
  width: 100%;
}
.about_img_holder{
  width: 100% !important;
  background-color: #be3d63;
  height: 10rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logo_ts{
  font-size: 3.25rem;
  font-weight: 700;
  letter-spacing: 2px;
  color: #fff;
}

.about_point_card{
  display: flex;
  align-items: center;
  gap: 2rem;
}
.icon_holder{
  /* background-color: #fff; */
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  position: relative;
  z-index: 1;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.black_dot{
  width: 0.6rem;
  height:0.6rem;
  background-color: rgb(40, 40, 40);
  border-radius: 0.6rem;
}
.icon_holder > i{
  font-size: 1.85rem;
  color: rgb(245, 19, 42);
}
.title_holder_ts{
  font-size: 1.5rem;
  font-weight: 600;
  color:rgb(45, 50, 48)
}
.column_content_flex{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.about_point_List{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 786px) {
  .mi_two_column_md {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    padding: 0rem;
  }
  .mi_two_column_xl {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    padding: 0rem;
  }
  .mi_two_column_xl.alternative{
    display: flex !important;
    flex-direction: column-reverse !important;
    gap: 3rem !important;
    }
  .logo_ts{
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 2px;
    color: #fff;
  }

}

@media screen and (max-width: 786px) {
  .about_headline_grid{
    display: flex;
    flex-direction: column-reverse;
    gap: 3rem;
    padding: 0rem;
  }
  .logo_ts{
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 2px;
    color: #fff;
  }
  .about_img_holder{
    width: 100%;
  }
}

@media screen and (max-width: 586px) {
  .title_holder_ts{
    font-size: 1.125rem;
    font-weight: 600;
    color:rgb(45, 50, 48)
  }
  .about_point_List{
    display: flex;
    flex-direction: column;
    gap: 0.85rem;
    margin-bottom: 3rem;
  }
}