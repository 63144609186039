$Brand-Blue: #3e3b6f;
$Brand-Yellow: #fcdc73;

$Pink-Bold: #be3e63;
$Pink-Medium: #d26786;
$Pink-Light: #c55a83;

$Black-Bold: #111111;
$Black-Medium: #333333;
$Black-Light: #4d4f5c;
$Black-Thin: #a8a8a8;

$White-Bold: #707070;
$White-Semibold: #d6d6d6;
$White-Medium: #f8f8f8;
$White-Light: #ffffff;

$Blue-Bold: #2e47b9;
$Blue-Semibold: #0093e9;
$Blue-Medium: #314ab2;
$Blue-Light: #5878c3;
$Blue-Mini: #c4d7fa;
$Blue-Thin: #c7d7f7;

$Orange-Bold: #fa7923;
$Orange-Medium: #ffa83b;
$Orange-Light: #ffab1d;

$Error: #ff0000;
$Error-light: #fc7373;
$Pass: #489e48;
