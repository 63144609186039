// Globle Imports
@import "../../assets/styles/globle.scss";

// App Styles
#sidebar {
  grid-area: Sidebar;
  width: 11rem;
  height: calc(100vh - 4rem);
  background: $Pink-Medium;
  position: fixed;
  top: 4rem;
  left: 0;
  z-index: 100;
  padding: 1.5rem 0.7rem 0 0.7rem;
  transition: 0.5s;
}

.side-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.side-nav-link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 0.5rem;
  text-decoration: none;
  padding: 0.5rem 0 0.5rem 0.5rem;
  position: relative;
  color: $White-Light;
  margin-bottom: 1.2rem;
  transition: all 1s !important;
  border-radius: 0.4rem;
}

.side-nav-link:hover {
  background-color: $Pink-Bold;
  color: $White-Light !important;
}

.active {
  background-color: $Pink-Bold;
  color: $White-Light;
}

.side-nav-icon {
  font-size: 1.2rem;
}

.side-nav-text {
  font-size: 0.75rem;
}

.sponser-logo {
  margin-bottom: 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: scale(1);
  transition: all 1s !important;
  opacity: 1;
}
.sponser-text {
  font-size: 0.65rem;
  color: $White-Light;
  font-weight: 300;
}
.sponser-logo-image {
  width: 5rem;
  margin-top: 0.3rem;
}

@media screen and (max-width: 780px) {
  .sponser-logo {
    display: none;
    transform: scale(0);
    opacity: 0;
  }
}

@media screen and (max-width: 768px) {
  #sidebar {
    width: 3.6rem;
    padding: 1.5rem 0.7rem 0 0.7rem;
  }
}

@media screen and (max-width: 586px) {
  #sidebar {
    width: 11rem;
    padding: 1.5rem 0.7rem 0 0.7rem;
    top: 3rem;
    left: -100%;
  }
  .show-active {
    left: 0 !important;
  }
}
