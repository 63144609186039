.showcase_container {
  background: url("../../../assets/icons/images/showcase.png") center no-repeat;
  background-size: cover;
}
.showcase_inner_wrapper {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.showcase_content {
  max-width: 56rem;
}
.showcase_title_ts {
  font-weight: 700;
  color: var(--white);
  line-height: 5.25rem;
}
.showcase_mobile_btns{
  display: none;
}

@media screen and (max-width: 586px) {
  .showcase_title_ts {
    font-weight: 600;
    color: var(--white);
    line-height: 3.25rem;
  }
  .showcase_inner_wrapper {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .showcase_mobile_btns{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
  }
}
