.Story__main_container {
  background-color: #fcedc6;
}
.Story_two_column_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  padding: 0 4rem;
}
.Story__content_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.story_heading_text {
  font-size: var(--heading_fs);
  font-weight: 600;
  color: var(--black_color);
}

.Story__banner_wrapper {
  display: flex;
  width: 100%;
  height: 28rem;
  border-radius: 1rem;
  margin-top: -16rem;
}
.Story__banner_wrapper > img {
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.Story__mobile_banner_wrapper {
  display: none;
}
.Story__layout_wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
@media screen and (max-width: 992px) {
  .Story__banner_wrapper {
    display: none;
  }
  .section_null_space {
    display: none;
  }
  .Story__mobile_banner_wrapper {
    display: flex;
    width: 100%;
    height: 28rem;
    border-radius: 1rem;
  }
  .Story__mobile_banner_wrapper > img {
    width: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }
}

@media screen and (max-width: 586px) {
  .Story_two_column_grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    padding: 0 0rem;
  }
}
