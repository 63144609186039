.Contactus__main_container {
  background-color: #fff;
}
.Contactus__layout_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      102.17deg,
      rgba(2, 0, 17, 0.76) 6.5%,
      rgba(23, 31, 65, 0.569182) 42.52%,
      rgba(21, 31, 66, 0.51) 52.61%,
      rgba(21, 34, 74, 0.604157) 61.37%,
      rgba(0, 10, 31, 0.8) 100%
    ),
    url("../../../assets/icons/pictures/contact_image.jpg") center no-repeat;
  background-size: cover;

  padding: 6rem 0rem;
  border-radius: 1rem;
}
.Contactus__menu_action_wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.Contactus__content_info {
  max-width: 50rem;
  padding: 2rem o;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2rem;
}
