html {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}
body {
  overflow-x: hidden;
}
.mi_white {
  color: #fff !important;
}
.site_body_ts {
  color: var(--grey_color);
  line-height: 2rem;
}
.Page__main_container {
  width: 100%;
  max-width: 80rem;
  display: block;
  margin: 0 auto;
  padding: 0 1.25rem;
}

.section_space {
  padding: 5rem 0;
}
.section_top_space {
  padding-top: 5rem;
}

.section_bottom_space {
  padding-bottom: 5rem;
}

.section_left_space {
  padding-left: 4rem;
}

.section_null_space {
  width: 100%;
  height: 10rem;
  background-color: #fff;
}
@media screen and (max-width: 786px) {
  .section_space {
    padding: 4rem 0;
  }
  .section_top_space {
    padding-top: 4rem;
  }

  .section_bottom_space {
    padding-bottom: 4rem;
  }
}
@media screen and (max-width: 586px) {
  .section_left_space {
    padding-left: 0rem;
  }
  .section_space {
    padding: 3.5rem 0;
  }
  .section_top_space {
    padding-top: 3.5rem;
  }

  .section_bottom_space {
    padding-bottom: 3.5rem;
  }
}
