// Globle Imports
@import "../globle.scss";

// Indent Styles
.indent-main-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5rem;
  margin: 2.5rem 0;
}
.indent-detail-area {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
  width: 100%;
  background: $White-Light;
  border-radius: 0.3rem;
  padding: 1rem;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}
.beneficiary-detail-area {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
  width: 100%;
  background: $White-Light;
  border-radius: 0.3rem;
}
.commodity-detail-area {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
  width: 100%;
  background: $White-Light;
  border-radius: 0.3rem;
  padding: 1rem;
}
.detail-area-heading {
  position: absolute;
  left: 0rem;
  top: -1.85rem;
}
.indent-grid-card-body {
  padding: 1rem;
}

.indent-grid-card-footer {
  border-top: 2px solid $White-Semibold;
  // border-top: 2px solid $Blue-Mini;
  padding: 1rem;
}
.indent-flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.indent-flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.indent-data-group-grid {
  display: grid;
  grid-template-columns: 1.5fr 2fr;
}
.indent-data-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2rem;
}
.mb-large {
  margin-bottom: 1.5rem;
}
.indent-text-wrapper {
  display: flex;
  flex-direction: column;
}
.indent-edit-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  padding: 0.4rem 0;
  padding-left: 1rem;
  padding-right: 0.3rem;
  border: 1px solid $Blue-Mini;
  border-radius: 0.3rem;
  background: $White-Light;
  color: $Black-Bold;
  transition: 0.5s;
  outline: none;
}
.indent-edit-text-active {
  background: $Blue-Mini;
  color: $Black-Bold;
}
.id-status-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.btn-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  padding: 0.1rem 0;
}
@media screen and (max-width: 1156px) {
  .indent-main-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem 1.5rem;
    margin: 2.5rem 0;
  }
}
@media screen and (max-width: 856px) {
  .indent-main-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    margin: 2.5rem 0;
  }
}
