:root {
  --animate_text: 8rem;
  --main_title_text: 2.75rem;
}

.Showcase__main_container {
  background: linear-gradient(
      102.17deg,
      rgba(2, 0, 17, 0.76) 6.5%,
      rgba(23, 31, 65, 0.569182) 42.52%,
      rgba(21, 31, 66, 0.51) 52.61%,
      rgba(21, 34, 74, 0.604157) 61.37%,
      rgba(0, 10, 31, 0.8) 100%
    ),
    url("../../../assets/icons/pictures/hero3.jpg") center no-repeat;
  background-size: cover;
}
.Showcase__layout_wrapper {
  width: 100%;
  height: 65vh;
  display: flex;
  align-items: center;
}
.Showcase__content_info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.Main__headline_title {
  font-size: 4rem;
  color: var(--white_color);
  line-height: 1.5;
}
.Main__headline_subtitle {
  font-size: 3.5rem;
  color: var(--white_color);
  line-height: 1.5;
}
.slider_count_text {
  color: var(--white_color);
  font-weight: 600;
}

.Showcase__slider_info {
  display: grid;
  grid-template-columns: 1fr 3rem;
  align-items: center;
  gap: 1rem;
}
.Showcase__slider_count {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.line_vector {
  width: 100%;
  height: 1px;
  background-color: #ddd;
}

.heading_wrapper {
  display: flex;
  flex-direction: column;
}

.leader_showcase_title {
  font-size: var(--main_title_text);
  font-weight: 700;
  color: var(--white_color);
  line-height: 1.5;
}

.leader_showcase_title.max_width {
  max-width: 60rem;
}

.brand_text {
  position: relative;
  font-size: var(--animate_text);
  text-transform: capitalize;
  color: transparent;
  -webkit-text-stroke: 3px #ffffff;
  cursor: pointer;
  margin-top: 0rem;
  opacity: 1;
}

.brand_text::before {
  content: attr(data-fill-text);
  position: absolute;
  width: 0;
  height: 100%;
  color: #ffffff;
  -webkit-text-stroke: 0px transparent;
  overflow: hidden;
  transition: width 3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.brand_text.text_active::before {
  width: 100%;
}

@media screen and (max-width: 992px) {
  :root {
    --animate_text: 7rem;
    --main_title_text: 2.45rem;
  }
  .brand_text {
    -webkit-text-stroke: 2px #ffffff;
  }
}

@media screen and (max-width: 786px) {
  :root {
    --animate_text: 6rem;
    --main_title_text: 2.125rem;
  }

  .brand_text {
    -webkit-text-stroke: 2px #ffffff;
  }
}

@media screen and (max-width: 586px) {
  :root {
    --animate_text: 4rem;
    --main_title_text: 1.6rem;
  }
  .brand_text {
    -webkit-text-stroke: 2px #ffffff;
  }
}
