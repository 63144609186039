/* Variable */
:root {
  --btn_primary: #be3d63;
  --font_black: #2d394b;
  --font_grey: #6a6c70;
  --font_slate: #7b828d;
  --font_dark: #4b5d7a;
  --btn_border: #afb7c4;
  --brand_blue: #f5f9ff;
  --brand_grey: #f1f8f6;
  --dark: #111;
  --white: #fff;
  --light: #fafafa;
}
.landing_container {
  background-color: #fff;
}

/* Typography */
.mi_title {
  font-weight: 600;
 font-size: calc(1.75rem + 2vw);
}
.mi_heading {
  font-size: calc(1.25rem + 1.25vw);
  font-weight: 600;
}
.mi_headingLite {
  font-size: calc(1.125rem + 1.125vw);
  font-weight: 600;
}
.mi_maxheading {
  font-size: 1.8rem;
}
.mi_miniheading {
  font-size: 1.5rem;
}
.mi_subheading {
  font-size: 1.25rem;
}
.mi_subheadingLite {
  font-size: 1.125rem;
}
.mi_body {
  font-size: 1rem;
}
.mi_note {
  font-size: 0.875rem;
}

/* Button */

.mi_btn {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 2rem;
}
.mi_btn_xl {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.6rem 1.25rem;
}

.mi_btn_md {
  font-size: 1rem;
  padding: 0.525rem 1.5rem;
}

/* Button Colors */

.mi_btn_primary {
  background-color: var(--btn_primary) !important;
  border: 2px solid var(--btn_primary) !important;
  color: #fff !important;
}

.mi_btn_secondary {
  background-color: var(--white) !important;
  border: 2px solid var(--btn_primary) !important;
  color: var(--btn_primary) !important;
}

.mi_btn_white {
  background-color: var(--white) !important;
  border: 2px solid var(--white) !important;
  color: var(--font_dark) !important;
}
